import React from 'react';
import ReactDOM from 'react-dom';
import {Auth0Provider} from "@auth0/auth0-react";
import {App} from "./App";
import './index.css';


ReactDOM.render(
    <Auth0Provider
        domain="metapack-dev.eu.auth0.com"
        clientId="PQkJSRSS5v4JgvnVzKHYqSl0FmmTznYy"
        redirectUri={window.location.origin}
        organization="org_d0yZK8KocXOkfZZB"
    >
        <App/>
    </Auth0Provider>,
    document.getElementById("root")
);


