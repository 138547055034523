
export const items = [
    {id: 1, text: 'DM 1', url: 'https://dm.metapack.com', img: 'dm.png', containerId: 1},
    {id: 2, text: 'Scheduler DM 1 C', url: 'https://dm-scheduler-c-dm1.metapack.com/', img: 'dm-s.png', containerId: 2},
    {id: 3, text: 'Scheduler DM 1 D', url: 'https://dm-scheduler-d-dm1.metapack.com/', img: 'dm-s.png', containerId: 2},
    {id: 4, text: 'Scheduler DM 1 E', url: 'https://dm-scheduler-e-dm1.metapack.com/', img: 'dm-s.png', containerId: 2},
    {id: 5, text: 'DM 2', url: 'https://dm2.metapack.com', img: 'dm.png', containerId: 1},
    {id: 6, text: 'Scheduler DM 2 C', url: 'https://dm-scheduler-c-dm2.metapack.com/', img: 'dm-s.png', containerId: 2},
    {id: 7, text: 'Scheduler DM 2 D', url: 'https://dm-scheduler-d-dm2.metapack.com/', img: 'dm-s.png', containerId: 2},
    {id: 8, text: 'Scheduler DM 2 E', url: 'https://dm-scheduler-e-dm2.metapack.com/', img: 'dm-s.png', containerId: 2},
    {id: 9, text: 'DM 3', url: 'https://dm3.metapack.com', img: 'dm.png', containerId: 1},
    {
        id: 10,
        text: 'Scheduler DM 3 C',
        url: 'https://dm-scheduler-c-dm3.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 11,
        text: 'Scheduler DM 3 D',
        url: 'https://dm-scheduler-d-dm3.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 12,
        text: 'Scheduler DM 3 E',
        url: 'https://dm-scheduler-e-dm3.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {id: 13, text: 'DM 4', url: 'https://dm4.metapack.com', img: 'dm.png', containerId: 1},
    {
        id: 14,
        text: 'Scheduler DM 4 C',
        url: 'https://dm-scheduler-c-dm4.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 15,
        text: 'Scheduler DM 4 D',
        url: 'https://dm-scheduler-d-dm4.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 16,
        text: 'Scheduler DM 4 E',
        url: 'https://dm-scheduler-e-dm4.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {id: 17, text: 'DM 5', url: 'https://dm5.metapack.com', img: 'dm.png', containerId: 1},
    {
        id: 18,
        text: 'Scheduler DM 5 C',
        url: 'https://dm-scheduler-c-dm5.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 19,
        text: 'Scheduler DM 5 D',
        url: 'https://dm-scheduler-d-dm5.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 20,
        text: 'Scheduler DM 5 E',
        url: 'https://dm-scheduler-e-dm5.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {id: 21, text: 'DM 6', url: 'https://dm6.metapack.com', img: 'dm.png', containerId: 1},
    {
        id: 22,
        text: 'Scheduler DM 6 C',
        url: 'https://dm-scheduler-c-dm6.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 23,
        text: 'Scheduler DM 6 D',
        url: 'https://dm-scheduler-d-dm6.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 24,
        text: 'Scheduler DM 6 E',
        url: 'https://dm-scheduler-e-dm6.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {id: 25, text: 'DM 8', url: 'https://dm8.metapack.com', img: 'dm.png', containerId: 1},
    {
        id: 26,
        text: 'Scheduler DM 8 C',
        url: 'https://dm-scheduler-c-dm8.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 27,
        text: 'Scheduler DM 8 D',
        url: 'https://dm-scheduler-d-dm8.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 28,
        text: 'Scheduler DM 8 E',
        url: 'https://dm-scheduler-e-dm8.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {id: 29, text: 'DM Asos', url: 'https://asos.metapack.com', img: 'dm.png', containerId: 1},
    {
        id: 30,
        text: 'Scheduler DM ASOS C',
        url: 'https://dm-scheduler-c-dma.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 31,
        text: 'Scheduler DM ASOS D',
        url: 'https://dm-scheduler-d-dma.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 32,
        text: 'Scheduler DM ASOS E',
        url: 'https://dm-scheduler-e-dma.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {id: 33, text: 'DM M&S', url: 'https://marksandspencer.metapack.com/', img: 'dm.png', containerId: 1},
    {
        id: 34,
        text: 'Scheduler DM M&S C',
        url: 'https://dm-scheduler-c-dmm.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 35,
        text: 'Scheduler DM M&S D',
        url: 'https://dm-scheduler-d-dmm.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {
        id: 36,
        text: 'Scheduler DM M&S E',
        url: 'https://dm-scheduler-e-dmm.metapack.com/',
        img: 'dm-s.png',
        containerId: 2
    },
    {id: 37, text: 'DM Delta', url: 'https://metapack-dm-delta.metapack.com', img: 'dm.png', containerId: 3},
    {
        id: 38,
        text: 'Scheduler DM Delta C',
        url: 'https://dm-scheduler-c-dmd-eu-west-1.sbx.metapack.com/',
        img: 'dm-s.png',
        containerId: 4
    },
    {
        id: 39,
        text: 'Scheduler DM Delta D',
        url: 'https://dm-scheduler-d-dmd-eu-west-1.sbx.metapack.com/',
        img: 'dm-s.png',
        containerId: 4
    },
    {
        id: 40,
        text: 'Scheduler DM Delta E',
        url: 'https://dm-scheduler-e-dmd-eu-west-1.sbx.metapack.com/',
        img: 'dm-s.png',
        containerId: 4
    },
    {id: 41, text: 'DM Int EU', url: 'https://dm-ux-shd.eu-west-1.int.aws.metapack.io/', img: 'dm.png', containerId: 3},
    {
        id: 42,
        text: 'Scheduler DM Int EU C',
        url: 'https://dm-scheduler-c-shd-eu-west-1.int.metapack.com/',
        img: 'dm-s.png',
        containerId: 4
    },
    {
        id: 43,
        text: 'Scheduler DM Int EU D',
        url: 'https://dm-scheduler-d-shd-eu-west-1.int.metapack.com/',
        img: 'dm-s.png',
        containerId: 4
    },
    {
        id: 44,
        text: 'DM Sbx US',
        url: 'https://dm-app-shd.us-east-2.sbx.aws.metapack.io/',
        img: 'dm.png',
        containerId: 3
    },
    {
        id: 45,
        text: 'Scheduler DM Sbx US C',
        url: 'https://dm-scheduler-c-shd-us-east-2.sbx.metapack.com/',
        img: 'dm-s.png',
        containerId: 4
    },
]

export const tabs = [
    {id: 1, text: "DM Production", hidden: false},
    {id: 3, text: "DM Testing", hidden: false},
    {id: 2, text: "DM Schedulers Production", hidden: true},
    {id: 4, text: "DM Schedulers Testing", hidden: true},
    {id: 5, text: "Extra", hidden: false},
]
