import {useAuth0} from "@auth0/auth0-react";
import React, {useEffect} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TopMenu} from "./TopMenu";
import {Side} from "./Side";
import {Containers} from "./Containers";
import {Props} from "./Props";
import {items, tabs} from "./Defaults";

export const App = () => {
    const {isAuthenticated, isLoading, error, loginWithRedirect} = useAuth0();
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    });

    if (error !== undefined) {
        return <span>Ops... {error}</span>
    }

    if (isLoading) {
        return <span>Talking to Auth0...</span>;
    }

    if (!isAuthenticated) {
        return <span>Please wait...</span>
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="content-frame">
                <TopMenu/>
                <Side/>
                <Containers items={items} tabs={tabs}/>
                <Props/>
            </div>
        </DndProvider>
    );
};

